@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.credit-panel-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    //background: green;

    @include for-desktop-up {
        flex-wrap: unset;
        justify-content: center;
    }
}

.credit-panel {
    margin: 16px;
    display: flex;
    flex-flow: column;
    justify-content: center;

    @include for-desktop-up {
        padding: 0px 16px;
    }

}

.credit-label {
    color: #0E3B49;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    //text-align: center;
    text-transform: uppercase;

    @include for-phone-only {
        font-size: 12px;
    }

    @include for-desktop-up {
        font-size: 16px;
    }

}

.credit-value-wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.credit-value {
    color: #0E3B49;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 44px;

    @include for-phone-only {
        font-size: 26px;
    }
}

.btn-load-credit {
    height: 24px;
    font-size: 14px;
    line-height: 16px;
    //max-width: 240px;
    //width: 108px;

    border-radius: 7px !important;
    background-color: #0E3B49 !important;
    color: #EDF8F8 !important;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 0px 0px 0px 16px !important;
    padding: 8px !important;

    @include for-phone-only {
        height: 36px;
        width: 36px;
    }

    //@include for-tablet-portrait-up {
    //    //grid-column: 4/7;
    //    height: 64px;
    //    font-size: 14px;
    //    line-height: 16px;
    //
    //    margin-left: 16px !important;
    //}

    //@include for-desktop-up {
    //    //grid-column: 8/11;
    //    height: 24px;
    //    font-size: 14px;
    //    line-height: 16px;
    //}
}

.btn-load-credit:hover {
    color: white;
    font-size: 15px !important;
    line-height: 20px !important;
    background-color: #f99066 !important;
    transition: 0.3s !important;
}