@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

.dashboard-header-wrapper {
  position: relative;
  max-width: 1560px;
  //background: pink;
  padding: 0px 24px;

  overflow: hidden;
  //background: pink;


  @include for-phone-only {
    padding: 0px;
    overflow: unset;
  }
}

.dashboard-header {

  position: relative;
  //width: 100%;
  //min-height: 200px;

  max-width: 1560px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //justify-items: center;


  @include for-tablet-portrait-up {
    flex-wrap: unset;
  }

  //display: grid;
  //grid-template-columns: max-content min(900px, 1fr);
  //justify-content: space-between;


  //grid-template-columns: repeat(auto-fit, minmax(250px, 1fr) );

  //grid-column-gap: 20%;
  //justify-items: end;


  //border: 1px solid red;

  //.stats-wrapper:nth-child(1) {
  //  margin-right: 88px;
  //}

  :nth-child(n+1) {
    z-index: 2;
  }

  margin-bottom: 32px;

  @include for-phone-only {
    margin-bottom: 0px;
  }
}

.dashboard-header-background {
  width: 100%;
  height: 200px;
  //height: 160px;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: left;
  //margin-left: 128px;
  margin-left: 20%;
  opacity: 0.7;
  //margin-top: -48px;

  @include for-phone-only {
    justify-content: flex-end;
    margin-left: unset;
    opacity: 0.45;
  }

  @include for-tablet-portrait-up {
    //margin-top: 3%;
    margin-left: 7%;
    opacity: 0.35;
  }

  @include for-tablet-landscape-up {
    margin-top: 0%;
    margin-left: 15%;
  }

  @include for-desktop-up {
    margin-left: 20%;
  }

  img {

    @include for-phone-only {
      //height: unset;
      //width: 45%;
      height: 100%;
      object-fit: cover;
    }

    @include for-tablet-portrait-up {
      height: 150%;
    }

    @include for-desktop-up {
      width: 25%;
      //height: unset;
      object-fit: cover;
    }


  }
}