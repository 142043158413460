@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.msg-reader-header {
    font-size: 1.8rem;
    text-align: center;
    color: grey;
    margin-bottom: 48px
}

.msg-reader-subheader {
    font-size: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.79);
    margin-bottom: 48px;
    line-height: 1.3;
}

.mailbox-wrapper {
    width: 100%;
    height: 100%;
    //overflow-y: auto;
    padding-left: 0px;
    background-color : #F5FBFB;

    @include for-desktop-up {
        padding-left: 300px;
    }
}

.empty-mailbox-info {
    text-align: center;
    margin-top: 64px;
    color: #576464a3;
    font-size: 16px;
    height: 45px;
    line-height: 45px;
    font-weight: 300;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
}