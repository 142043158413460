@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.phone-dashboard-wrapper {
  font-family: Work Sans, sans-serif !important;
  //background: yellow !important;

  width: 100vw;

  overflow-y: auto;
  overflow-x: hidden;

  @include for-phone-only {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @include for-desktop-up {
    padding-left: 300px;
  }
}

.phone-dashboard-wrapper::-webkit-scrollbar {
  display: none;
}

.phone-dashboard {
  width: 100%;
  min-height: 100%;
  padding: 40px 16px !important;
  background-color: #F5FBFB;
  font-family: Work Sans, sans-serif !important;


  @include for-phone-only {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0px 16px !important;
  }
}

.make-call-time-of-call {
  gap: 2px;
  display: flex;
  padding-bottom: 12px;
  color: #505A5E;
  flex-direction: column;
  align-items: center;
}

.make-call-number-to-call {
  background-color: #FFFFFF;
  border-radius: 12px;
  height: 48px;
  gap: 8px;
  margin-top: 16px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.send-mail {
  background-color: #FFFFFF;
  border-radius: 12px;
  height: 48px;
  margin-top: 8px;
  font-size: 17px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.send-mail-input {
  border: none !important;
  padding: 0 0 !important;
}

.sms-modal-number-to-call-textarea {
  box-sizing: border-box !important;

  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 12px 16px !important;
  gap: 8px !important;

  height: 110px !important;
  background: #FFFFFF !important;
  border: 1px solid #EAEBEB !important;
  border-radius: 12px !important;
}

.make-call-number-to-call-input {
  border: none !important;
}

.btn-call {
    background-color: white !important;
    border: 1px solid #172E3A !important;
    color: #172E3A !important;;
    border-radius: 2px !important;
}

.btn-close {
  box-sizing: border-box !important;
  margin: 8px 0 16px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 12px !important;

  background: #FFFFFF !important;
  border: 1px solid #BEC4C5 !important;
  border-radius: 8px !important;

  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #153F4C !important;
}

.btn-call2 {
  height: 48px !important;
  //max-width: 240px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  padding: 12px 24px !important;
  border-radius: 12px !important;
  background-color: #27A57D !important;
  color: #FFFFFF !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;

  @include for-phone-only {
    //grid-column: 1/5;

    //height: 64px;
    //font-size: 16px;
    //line-height: 19px;

    height: 56px;
    font-size: 14px;
    line-height: 17px;


    //margin: 16px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }

  @include for-tablet-portrait-up {
    //grid-column: 4/7;
    height: 64px;
    font-size: 14px;
    line-height: 16px;
    margin-left: 0px !important;
  }

  @include for-desktop-up {
    //margin-left: 5px !important;
    //grid-column: 8/11;
    height: 64px;
    font-size: 14px;
    line-height: 16px;
  }
}

.btn-call:hover {
  background-color: #172E3A !important;
  color: #F5FBFB !important;
}

.call-phone-input {
  border: 0px !important;
  border-bottom: 2px dashed #0E3B49 !important;
  //border-bottom: 2px dashed rgb(79, 187, 188) !important;
  padding: 0px !important;
  color: #0E3B49 !important;
  //color: rgb(79, 187, 188) !important;
  font-size: 36px;
  margin-top: 12px !important;

  @include for-phone-only {
    font-size: 29px;
  }
}

.call-phone-input-invalid {
  border: 0px !important;
  border-bottom: 2px dashed #f76262 !important;
  padding: 0px !important;
  color: #0E3B49 !important;
  //color: rgb(79, 187, 188) !important;
  font-size: 36px;
  margin-top: 12px !important;
}

.call-phone-input::placeholder {
  font-size: 29px !important;
  color: #9c9c9c !important;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, .1);
}

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

.phone-list-empty-label {
  text-align: center;
  margin-top: 64px;
  color: #576464a3;
  font-size: 16px;
  height: 45px;
  line-height: 45px;
  font-weight: 300;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.phone-list-item .ui.button {
  font-family: Work Sans, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}

.make-call-modal-header {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0px;
  text-align: left;
  color: #153F4C;

}

.make-call-your-number-header {
  .copyIcon:hover {
    cursor: pointer;
  }
  .make-call-your-number-label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #505A5E;
  }
  .make-call-your-number-value {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  .make-call-your-number-sub-module {
    gap: 2px;
    display: flex;
    color: #505A5E;
    flex-direction: column;
    align-items: flex-start;
  }
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background-color: #FFFFFF;
  border-radius: 12px;
  height: 56px;


  font-size: 16px;
  gap: 12px;
  font-weight: 300;
  color: #576464;
  //color: #172E3A;
  margin-bottom: 4px;

  @include for-phone-only {
    font-size: 16px;
  }
}

.make-call-your-number {
  font-size: 31px;
  font-weight: 300;
  opacity: 0.8;
  color: #576464;;
  //color: #172E3A80;

  @include for-phone-only {
    font-size: 23px;
  }
}

.make-call-msg-cannot-call {
  font-size: 19px;
  font-weight: 300;
  color: red;
  width: 100%;
  text-align: center;
  margin: 24px 0px 24px 0px;
}

.make-call-dest-number-header {
  font-size: 20px;
  font-weight: 500;
  color: #0E3B49;
  //color: rgb(79, 187, 188);
  margin-top: 24px;
  margin-bottom: 4px
}