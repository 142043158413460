.mailboxWrapper {
    box-sizing: border-box;

    width: 750px;
    /*max-height: 575px;*/
    min-height: 200px;

    background: #FFFFFF;

    padding: 24px 24px 0px 24px;

    border: 1px solid #F2F3F3;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
}

.loadingWrapper {
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    min-height: 100px;
    top: 60px;
    z-index: 10;
}
.progressBarWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.loadingMask {
    position: absolute;
    width: 100%;
    height: 100%;

    border: 1px solid #F2F3F3;
    border-radius: 12px;

    backdrop-filter: blur(8px);
}

.progressBar {
    /*position: absolute;*/
    width: 216px !important;
    margin: auto !important;

    .bar {
        height: 60px !important;
    }
}

.progressBar > .ui.progress .bar  {
    height: 4px !important;
    border: 3px solid red !important    ;
}

.mailboxContentInner {
    position: relative;
}

.optionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
  }
  
.optionWrapper:hover{
   background: #f4fbfb;
   cursor: pointer;
}

.optionNoLabel {
    color: rgba(128, 128, 128, 0.478);
    opacity: 0.75;
}

.optionNoteWrapper {
    max-width: 50%;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
}

.optionDomainWrapper {
    max-width: 47%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.optionDomain {
    max-width: 90%;
    color: rgba(128, 128, 128, 0.85);
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-right: 2px;
}

