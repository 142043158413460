@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.mailbox-back-btn {
  border-radius: 7px !important;
  background-color: #0E3B49 !important;
  color: #EDF8F8 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
    //margin: 16px 0px !important
  margin: 8px 0px 16px 0px !important;
  height: 48px;
  width: 156px;
  font-size: 13px !important;

  @include for-phone-only {
      //height: 36px !important;
      width: 136px !important;
      padding: 0px !important;
      margin: 0px !important;
  }

  @include for-desktop-up {
      //margin-bottom: 36px !important;
  }

}

.mailbox-back-btn:hover {
  //font-size: 15px !important;
  //line-height: 20px !important;
  background-color: #f99066 !important;
  transition: 0.3s !important;
}

.mailbox-back-btn:hover:focus {
    ackground-color: green !important;
}

.mailbox-content {
    width: 100%;

    height: 100%;

    text-align: left;
    padding: 24px;
    //background: yellow;

    @include for-phone-only {
        padding: 0px;
    }

    @include for-desktop-up {
        padding: 40px;
        height: 100vh;

    }
}

.mailbox-content-inner {
    height: 100%;
    display: flex;
    flex-direction: column;

    //display: grid;
    //grid-template-rows: min-content minmax(0, 1fr);
    //background: yellow;
}

.mailbox-content-inner.mail-only{

    //height: 100vh;
    //height: -webkit-fill-available;
    //display: grid;
    //grid-template-rows: min-content minmax(0, 1fr);

    height: 100%;
    height: -webkit-fill-available;

    //background: yellow;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.mailbox-content-inner.mail-only::-webkit-scrollbar {
    display: none;
}

.mailbox-bottom-image {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    max-width: 40%;

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {
        bottom: 52px;
    }

    @include for-desktop-up {
        bottom: 0px;
    }
}


.mailbox-content-inner-navigation {

    width: 100%;
    background: #f5fbfb;
    position: sticky;
    top: 0px;
    z-index: 10;

    @include for-phone-only {
        padding: 16px 12px 12px;
    }

    @include for-desktop-up {
        position: unset;
        top: unset;
    }

}

.mailbox-filter-select-wrapper {
    //background: red !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include for-phone-only {
        flex-direction: column;
        font-size: 16px;
    }

    .mailbox-filter-select-entry-domain {
        opacity: 0.8;
        //text-align: center;
        font-size: 12px;

        @include for-phone-only {
            margin-top: 12px !important;
        }

        //i {
        //    margin-left: 8px;
        //}

    }

}


.refresh-button-error {
    background-color: #4FBBBC !important;
    color: white !important;
}

.mail-list-header {
    /*display: flex;*/
    margin: 82px 0 24px 0;
    /*background-color: yellow;*/
}

mail-list-header .ui.grid {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.btn-mail-body-action {
    border: solid 1px #010101 !important;
    background-color: transparent !important;
    color: #010101 !important;
}

.btn-mail-body-action:hover {
    background-color: #010101 !important;
    color: white !important;
}

.mail-list-entry {
    padding: 4px 0px !important;
    height: 100%;
    //overflow-y: scroll !important;
    overflow-x: auto;
    //overflow-y: auto;

    width: 100%;

    //background: green;
    /*background-color: yellow;*/
    /*margin-top: 56px;*/
    /*border-bottom: 1px solid #dedede;*/
    /*border: 2px red solid*/

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @include for-desktop-up {
        -ms-overflow-style: unset;
        scrollbar-width: unset;  /* Firefox */
    }
}

.mail-list-entry::-webkit-scrollbar {
    display: none;
}

.mail-list-entry-content {
    width: 100% !important;
    background-color: white;
    padding: 8px 36px 24px;

    @include for-phone-only {
        padding: 8px;
        width: 100vw !important;
    }
}

.only-mobile {
    display: none !important;
}

.only-not-mobile {
    display: block !important;
}

.message-body-info-wrapper {
    padding: 8px 24px !important;;
    width: 100% !important;;
    background-color: rgb(251, 251, 251) !important;;
    font-size: 12px !important;;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.message-body-info-text {
    width: 100% !important;
    text-align: right !important;
    margin-bottom: 8px !important;
    font-size: 13px !important;
}

.mail-list-limit {
    width: 100%;
    font-size: 14px;
    text-align: center;
    padding: 0px 16px;
    color: grey;
    font-weight: 300;
}

.mail-subject {
    color: #172E3A;
    margin-top: 6px;
    font-size: 19px;
    line-height: 25px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
}

.mail-unread {
    font-weight: 700 !important;
}

.active .mail-subject  {
    margin-top: 16px !important;
    overflow: unset;
    white-space: unset;
    font-weight: 500;
}

.mail-preview {
    color: #172E3A;
    margin-top: 8px;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 300;
}

.active .mail-preview {
    display: none;
}

.mail-min-header {
    color: #172E3A;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
}

.compromised .mail-summary {
    background-color: #f7ebeb;
}

.mail-list-entry.compromised > .ui.grid {
    background-color: #f7ebeb !important;
}

.mail-min-header-sender {
    display: flex;
    justify-content: center;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mail-min-header-sender-email {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #172E3A;
    font-size: 14px;
    font-weight: 300;
    margin-top: 2px;
    opacity: 0.75;
}

.mail-list-entry > .ui.grid {
    margin: 0px !important;
    background: white
}

.mail-safety-status-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: space-evenly;
}

.active .mail-safety-status-wrapper {
    padding-top: 12px;
}

.mail-safety-status-icon {
    opacity: 0.5 !important;
    margin: 0 auto !important;
}

.active .mail-safety-status-icon {
    padding-top: 24px;
}

.mail-close-button {
    display: none !important;
    cursor: pointer;
    font-size: 19px !important;
}

.active .mail-close-button {
    width: 16px;
    margin: 0 auto;
    color: #172E3A;
    opacity: 0.45;
    display: inline-block !important;
}



.active .mail-status-column {
    justify-content: unset !important;
}

.mail-avatar-small {
    display: none !important;
}

.mail-safety-status-icon-small {
    display: none !important;
}

.mail-body-close-btn {
    display: none !important;
}

.mail-avatar-wrapper > img {
    height: 34px !important;
    font-size: 40px !important;
}

.mailbox-content .ui.active.progress .bar {
    height: 8px !important;
    background: #4fbbbc; //#3E8A98; //#4fbbbc;
    /*margin-left: 16px;*/
}

.mailbox-content .ui.progress > .label {
    /*font-size: 16px;*/
    font-weight: 300;
}