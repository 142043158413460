.identity-field-copyable-wrapper {
  display: flex;
  justify-content: space-between;
  height: 24px;

  .copy.icon {
    cursor: pointer;
    color: grey;
  }

  .identity-field-copyable-message {
    background-color: #3E8A98;
    opacity: 0.6;
    width: 100%;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
  }

  .identity-field-copyable-actions {
    display: flex;
    flex-direction: row;
    gap: 8px;

    i {
      cursor: pointer;
    }

  }

}



