@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.new-identity {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    max-width : 900px;
    //background: yellow;

    padding: 40px 0px 40px 24px;

    @include for-phone-only {
        //padding: 24px 16px 0px 16px;
        padding: 0px 16px 0px 16px;
    }

    @include for-tablet-portrait-up {
        flex: 1 1;
    }

    @include for-desktop-up {
        //background: blue;
        padding: 40px 24px;
    }


    .btn-create-id {
        height: 64px;
        //max-width: 240px;

        border-radius: 7px !important;
        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;
        font-family: 'Work Sans', sans-serif !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        text-align: center !important;

        @include for-phone-only {
            //grid-column: 1/5;

            //height: 64px;
            //font-size: 16px;
            //line-height: 19px;

            height: 56px;
            font-size: 14px;
            line-height: 17px;


            //margin: 16px 0px 0px 0px !important;
            margin: 0px 0px 0px 0px !important;
        }

        @include for-tablet-portrait-up {
            //grid-column: 4/7;
            height: 64px;
            font-size: 14px;
            line-height: 16px;
            margin-left: 0px !important;
        }

        @include for-desktop-up {
            margin-left: 5px !important;
            //grid-column: 8/11;
            height: 64px;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .btn-create-id:hover {
        color: white;
        font-size: 15px !important;
        line-height: 20px !important;
        background-color: #f99066 !important;
        transition: 0.3s !important;

        @include for-phone-only {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .btn-upgrade-id {
        height: 64px;
        width: 100%;

        //max-width: 240px;

        border-radius: 7px !important;
        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;
        font-family: 'Work Sans', sans-serif !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        text-align: center !important;


        @include for-phone-only {
            //grid-column: 1/5;
            //height: 64px;
            //font-size: 16px;
            //line-height: 19px;
            margin: 16px 0px 0px 0px !important;

            height: 56px;
            font-size: 14px;
            line-height: 17px;
        }

        //@include for-phone-only {
        //    //grid-column: 1/5;
        //    height: 64px;
        //    font-size: 16px;
        //    line-height: 19px;
        //    margin: 16px 0px 0px 0px !important;
        //}

        @include for-tablet-portrait-up {
            //grid-column: 4/7;
            height: 64px;
            font-size: 14px;
            line-height: 16px;

            margin-left: 0px !important;
            //margin-left: 5px !important;
        }

        @include for-desktop-up {
            margin-left: 5px !important;
            max-width: 240px;
            height: 64px;
            width: 196px;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .btn-upgrade-id:hover {
        color: white;
        font-size: 15px !important;
        line-height: 20px !important;
        background-color: #f99066 !important;
        transition: 0.3s !important;
    }

    .id-creation-success {
        height: 100%;
        background-color: #3E8A98; //#21ba45;
        font-size: 19px;
        //line-height: 25px;
        display: flex;
        justify-content: center;
        font-weight: 300;
        align-items: center;
        color: white;
        padding: 16px 32px;
        border-radius: 4px;
        text-align: center;
        max-width: 609px;

        @include for-phone-only {
            width: 100%;
            font-size: 17px;
        }

        i {
            margin-right: 16px;
        }
    }

    .id-creation-error {
        height: 100%;
        background-color: #f76262;
        font-size: 19px;
        //line-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        align-items: center;
        color: white;
        padding: 16px 32px;
        border-radius: 4px;
        text-align: center;

        @include for-phone-only {
            width: 100%;
        }

        i {
            margin-right: 16px;
        }
    }

}

.new-identity-form {

    display: flex;
    flex-wrap: wrap;

    .new-identity-form-elem {
        flex: 1 1 150px;
        margin: 5px 5px 5px 0px;

        @include for-tablet-portrait-up {

        }

        @include for-desktop-up {
            margin-left: 5px;
        }
    }

    .new-identity-form-elem:first-of-type {

        @include for-desktop-up {
            margin-left: 0px;
        }

    }

    .new-identity-input {
        border: 1px solid #0E3B4944;
        border-radius: 7px;
        height: 64px;
        //max-width: 240px;

        input {
            box-sizing: border-box;
            border: 12px solid rgba(38, 94, 95, 0.24);
            border-radius: 7px;
            background-color: #FFFFFF;
            //box-shadow: 0 12px 24px -2px rgba(14, 59, 73, 0.1) !important;

            color: #576464;
            font-family: 'Work Sans', sans-serif !important;
            letter-spacing: 0;

            //height: 16px;
            //width: 88px;
            //opacity: 0.45;
            font-size: 14px;
            line-height: 16px;

            //padding-left: 8px;
        }

        @include for-phone-only {
            height: 64px;
            font-size: 16px;
            line-height: 19px;
            margin: 0px 0px 12px 0px;
        }

        @include for-tablet-portrait-up {
            height: 64px;
            font-size: 16px;
            line-height: 19px;
        }

        @include for-desktop-up {
            height: 64px;
            font-size: 16px;
            line-height: 19px;
        }

        //input:hover {
        //    box-shadow: 0 16px 24px 4px rgba(14, 59, 73, 0.1),
        //    0 24px 24px 0px rgba(7, 0, 0, 0.1) !important;
        //}

        input:focus {
            border: 0px;
            //color: #F08769 !important;

            //@include for-tablet-portrait-up {
            //    box-shadow: 0 16px 24px 4px rgba(14, 59, 73, 0.1),
            //    0 24px 24px 0px rgba(7, 0, 0, 0.1) !important;
            //}
        }


        ////flex: 0 1 150px;
        //font-size: 16px !important;
        ////max-width: 196px;
        //min-height: 48px;
        //border-radius: 2px !important;
        ////margin: 8px;

        i {
            color: rgba(38,94,95,0.84);
            z-index: 3;
            margin-left: 4px !important;
            margin-right: 4px !important;
        }
    }

    .new-identity-input.ui[class*="left icon"].input > input {
        padding-left: 48px !important;
    }

    ::placeholder {
        //opacity: 1;
        opacity: 0.45;
        //z-index: 2;
        color: #576464 !important;
    }

}

.new-identity ::placeholder {
    color: grey !important;
}

.new-identity-header {
    min-height: 25px;
    //width: 480px;
    color: #0E3B49;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;

    @include for-phone-only {

    }

    @include for-tablet-portrait-up {
        width: 480px;
    }
}

.new-identity-subheader {

    color: #576464;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 8px 0px;

    @include for-phone-only {
        margin: 12px 0px;
        font-size: 14px;
    }

    @include for-desktop-up {
        line-height: 28px;
        //max-width: 609px;
        width: 609px;
    }

}

.new-identity-limit-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    //justify-content: end;
    max-width: 609px;

    color: #F08769; //#4FBBBC;
    padding-left: 0px;
    padding-top: 8px;
    //line-height: 2px;
    margin-bottom: 8px;
    letter-spacing: 0.3px;
}

.new-identity-limit-info-text {
    font-size: 16px;
    line-height: 29px;
    margin-right: 16px;
    margin-bottom: 8px;
    font-weight: 500;

    @include for-phone-only {
        font-size: 13px;
        line-height: 25px;
    }
}

.new-identity-domain-limit-info {
    color: #F08769; //#4FBBBC;

    @include for-phone-only {
        margin-top: 12px;
    }

    @include for-tablet-portrait-up {
        margin-top: 4px;
    }
}