.filterWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 48px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin-bottom: 12px;
}

.filterWrapper > i {
  width: 24px;
  height: 24px;
}

.filterIdentitySelect {
  box-sizing: border-box !important;

  /* Auto layout */
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 12px 16px !important;
  gap: 8px !important;

  width: 548px !important;
  height: 48px !important;

  background: transparent !important;

  border: 1px solid #EAEBEB !important;
  border-radius: 12px !important;

  background: pink !important;

  input {
   height: 100%;
   padding-left: 48px !important;
  }
}

//.filterIdentitySelect div:first-child {
//  width: 548px !important;
//  height: 48px !important;
//
//  background: transparent !important;
//
//  border: 1px solid #EAEBEB !important;
//  border-radius: 12px !important;
//
//  background: green !important;
//}


.selectTrigger {
  display: flex;
  align-items: center;
}

