@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.dashboard-wrapper {
    width: 100vw;
    height: 100%;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }

    //@include for-phone-only {
    //    ::-webkit-scrollbar {
    //        width: 0px;  /* Remove scrollbar space */
    //        background: transparent;  /* Optional: just make scrollbar invisible */
    //    }
    //}

    @include for-desktop-up {
        padding-left: 300px;

        ::-webkit-scrollbar {
            width: unset;
            background: unset;
        }
    }

}

.dashboard {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 40px 16px!important;
    background-color : #F5FBFB;

    @include for-phone-only {
        padding: 16px 16px 0px 16px !important;
        //border: 5px solid green;
        display: flex;
        flex-direction: column;
    }
}

//.ids-dashboard-header {
//    textAlign: left;
//    margin: 64px 24px 24px 0;
//}
//
//

//.btn-upgrade-account {
//    font-family: 'Work Sans', sans-serif !important;
//    height: 42px !important;
//    color: white !important;
//    font-size: 16px !important;
//    min-width: 100px;
//    background-color: #4fbbbc !important;;
//    justify-content: center;
//    align-items: center;
//    border-radius: 2px !important;
//    font-weight: 500 !important;
//    margin: 0px !important;
//}
//
//.btn-upgrade-account:hover {
//    background-color: rgb(57, 146, 147) !important;
//}
//
//.loader-create-id {
//    /*height: 100px;*/
//    /*width: 156px;*/
//    border-radius: 0;
//}
//
//.info-msg-wrapper {
//    position: absolute;
//    top: 0;
//    padding-left: 0;
//}




//
///*------------------------------------------
//  Responsive Grid Media Queries - 1280, 1024, 768, 480
//   1280-1024   - desktop (default grid)
//   1024-768    - tablet landscape
//   768-480     - tablet
//   480-less    - phone landscape & smaller
//--------------------------------------------*/
//@media all and (min-width: 1025px) and (max-width: 1280px) {
//
//}
//
//@media all and (min-width: 768px) and (max-width: 1025px) {
//
//    /*.dashboard-header-column {*/
//    /*    margin-top: 32px;*/
//    /*}*/
//
//    .btn-create-id {
//        height: 48px !important;
//    }
//
//    .btn-upgrade-account {
//        height: 42px !important;
//    }
//
//    .dashboard-wrapper {
//        background-color: yellow;
//        padding-left: 0px;
//    }
//
//    .dashboard {
//        height: 100%;
//        padding: 16px 24px;
//    }
//
//    /*.dashboard-header {*/
//    /*    padding-left: 0px !important;*/
//    /*    padding-right: 0px !important;*/
//    /*}*/
//
//    /*.dashboard-header-column {*/
//    /*    margin-top: 16px !important;*/
//    /*    margin-bottom: 16px !important;*/
//    /*}*/
//
//    .limit-info-wrapper {
//        padding-left: 0px !important;
//    }
//}
//
//@media all and (min-width: 480px) and (max-width: 768px) {
//
//    .dashboard-wrapper {
//        padding-left: 0px;
//    }
//
//    .dashboard {
//        height: 100%;
//        padding: 16px 24px;
//    }
//
//    /*.dashboard-header {*/
//    /*    padding-left: 32px !important;*/
//    /*    padding-right: 32px !important;*/
//    /*}*/
//
//    /*.dashboard-header-column {*/
//    /*    margin-top: 16px !important;*/
//    /*    margin-bottom: 16px !important;*/
//    /*    padding-left: 0px !important;*/
//    /*}*/
//
//    .limit-info-wrapper {
//        padding-top: 0px;
//    }
//
//    .btn-upgrade-account {
//        font-size: 14px !important;
//    }
//
//    /*.limit-info-wrapper {*/
//        /*display: unset;*/
//        /*padding: 1rem 1rem !important;*/
//        /*width: 100%;*/
//    /*}*/
//
//}
//
//@media all and (max-width: 480px) {
//
//    .ids-dashboard-header {
//        textAlign: left;
//        margin: 32px 0 0 0 ;
//    }
//
//    .btn-create-id {
//        height: 64px !important;
//        width: 100%;
//    }
//
//    .btn-upgrade-account {
//        /*height: 64px !important;*/
//        width: 100%;
//    }
//
//    .loader-create-id {
//        height: 56px;
//        width: 100%;
//    }
//
//    .info-msg-wrapper {
//        max-width: 90%;
//        margin-top: 12px;
//    }
//
//    .dashboard-wrapper {
//        padding-left: 0px;
//    }
//
//    .dashboard {
//        height: 100%;
//        scrollbar-width: none; /* Firefox */
//        -ms-overflow-style: none;  /* Internet Explorer 10+ */
//    }
//
//    .dashboard::-webkit-scrollbar { /* WebKit */
//        width: 0;
//        height: 0;
//    }
//
//    .limit-info-wrapper {
//        display: unset;
//        padding: 1rem 1rem !important;
//        width: 100%;
//    }
//
//    .limit-info-text {
//        font-size: 16px;
//        margin-bottom: 16px;
//        margin-right: 0px;
//        font-weight: 400;
//    }
//
//}