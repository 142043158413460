@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.ids-list-wrapper {
    width: 100%;
    //background: green;

    @include for-phone-only {
        width: unset;
        margin: auto -16px;
    }

}

.ids-list-wrapper-loader {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-phone-only {
        height: 20vh;
    }
}

.ids-list {


    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-evenly;

    @include for-phone-only {
        display: flex;
        overflow-x: auto;
        padding: 0px;
        //grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    //@include for-desktop-up {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        padding: 24px 0px;
    //}

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ids-list::-webkit-scrollbar {
    display: none;
}

.ids-list:after {

    @include for-phone-only {
        content: '';
        min-width: 22px;
    }
}

//.ids-list:before {
//
//    @include for-phone-only {
//        content: '';
//        min-width: 22px;
//    }
//
//}

.ids-list-wrapper::-webkit-scrollbar {
    display: none;
    //scrollbar-color: red yellow;
    //@include for-phone-only {
    //    height: 4px; /* height of horizontal scrollbar ← You're missing this */
    //    width: 4px; /* width of vertical scrollbar */
    //    border: 5px solid #d5d5d5;
    //}

}

.identity {
    //flex: 0 1 326px;
    margin: 24px;
    //32px 24px 0px;
    background: blue;
    //max-width: 326px;

    @include for-phone-only {
        margin: 16px;
    }
}

.id-field-wrapper {
    margin-top: 24px;
    position: relative;

    @include for-phone-only {
        margin-top: 14px;
    }
}

// .id-avatar {
//     height: 64px;
//     position: relative;

//     @include for-phone-only {
//         height: 48px;
//     }

//     img {
//         max-width: 58px !important;

//         @include for-phone-only {
//             max-width: 32px !important;
//         }
//     }

//      i {
//          //border: 3px solid blue;
//          position: absolute;
//          margin-top: -16px;
//          margin-left: 42px;
//          font-size: 2em;

//          @include for-phone-only {
//              margin-left: 24px;
//              font-size: 24px;
//          }
//     }

// }

.id-avatar-logo-wrapper {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-phone-only {
        width: 48px;
        height: 48px;
    }
}

.id-header-label {
    text-align: right;
    max-height: 64px;
    width: 100%;
    max-width: 180px; //200px; //190px;
    max-height: 54px;
    overflow-y: auto;
    overflow-wrap: break-word;
    scrollbar-width: thin;


    @include for-phone-only {
        margin-left: 16px;
    }

    //margin-left: 32px;

    a {
        font-size: 19px;
        color: #172E3A;
        font-weight: 700;
        line-height: 25px;

        @include for-phone-only {
            font-size: 14px;
            line-height: 22px;
        }

    }
}

.compromised-label {
    display: none;

    @include for-phone-only {
        display: block;
        padding: 4px 0px 16px 0px !important;
        text-align: center !important;
        font-weight: 400;
        font-size: 15px;
        color: #db2828;
    }
}

.id-content-email {
    display: inline-block;
    /*max-width: 196px;*/
    max-width: 95%;
    /*// fontSize: 14px;*/
    font-size: 14px;
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include for-phone-only {
        font-size: 10px;
    }
}

.id-content-password {
    font-size: 14px !important;
    max-width: 55%;
    margin-left: 16px
}

.identities-list-item {
    padding: 24px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);

    @include for-phone-only {
        flex: 0 0 240px;
        margin-bottom: 42px;
        margin-top: 42px;
        box-shadow: 0 25px 30px -12px rgba(0,0,0,0.25);
        //margin-bottom: 20px;
    }

    //@include for-desktop-up {
    //    flex: unset;
    //}

}

.identities-list-item-comment {
    display: inline-block;
    max-width: 100%;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    text-overflow: ellipsis;

    @include for-phone-only {
        max-width: 190px;
    }
}

.identities-list-item.compromised {

    border-radius: 6px;
    background-color: #ffede7 !important; //#F08769;
    /*border: #f76262 5px solid;*/
    box-shadow: unset;
}

.id-content-icon {
    color: #172E3A;
    opacity: 0.5 !important;
}

.id-content-email-copy-container {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: right;
    z-index: 10;
}

.id-content-email-copy-container > span {
    visibility: hidden;
}

.id-content-email-copy-container:hover > span {
    visibility: visible;
    cursor: pointer;
    color: #172E3A;
    opacity: 0.5;
    font-weight: 500;
}

.id-content-password-copy-container {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: right;
    z-index: 10;
}

.id-content-password-copy-container > span {
    visibility: hidden;
    line-height: 16px;
}

.id-content-password-copy-container:hover > span {
    visibility: visible;
    cursor: pointer;
    color: #172E3A;
    opacity: 0.5;
    font-weight: 500;
}


.identities-list-item-wrapper {
    margin-top: 16px !important;
}