.mailToolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 48px;

  margin-bottom: 24px;
}

.panelMailButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}


.btnMail.disabled {
  background: #F2F3F3;
  opacity: 0.4;
}

