.contentWrapper {
    background: #FCFCFC;
    max-height: 300px;
}

.dangerousInfoWrapper {
    border-width: 0px 1px 0px 1px;
    border-style: solid;
    border-color: #F2F3F3;
    padding: 8px 24px 24px 24px;
    // padding-bottom: 24px;
    // padding-top: 8px;
    // padding-left: 24px;
}

.dangerousInfo {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 12px 24px;
    gap: 16px;

    width: 100%;

    margin: 0 auto;

    /* Neutral/0 */
    background: #F2F3F3;
    //box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.dangerousInfo .isCompromised {
    background: red;
}

.dangerousInfoText {
    /* Paragraph 3 */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    /* Neutral/100 */
    color: #153F4C;
}

.dangerousInfoButton {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 6px;

    // height: 32px;

    /* White / White (100%) */
    background: #FFFFFF;

    /* Neutral/40 */
    border: 1px solid #BEC4C5;
    border-radius: 8px;

    /* Button Small text */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;

    /* identical to box height, or 133% */

    /* Neutral/100 */
    color: #153F4C;

    cursor: pointer;
}

.bodyWrapper {
    border-top: 10px solid #F2F3F3;
    background: white;
    //margin-top: 24px;
    padding: 40px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #F2F3F3;
    border-radius: 0px 0px 12px 12px;
    overflow-x: auto;
}