@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

.sms-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}



.sms-list-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sms-body {
  padding: 12px 0px !important;
  width: 100%;
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  color: #0E3B49;
  //background: yellow;

  @include for-phone-only {
    font-size: 16px;
    padding: 4px 0px 8px !important;
  }
}

.sms-body-unread {
  font-weight: 400;
}

.sms-list-item {
  background-color: rgba(244, 255, 255, 0.16);
}

.btn-sms {
  /*background-color: #464646 !important;*/
  /*color: #F5FBFB !important;*/
  background-color: white !important;
  border: 1px solid #172E3A !important;
  border-radius: 2px !important;
  color: #172E3A !important;;
}

.has-new-content::after {
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  margin-left: 48px;
  background: #3E8A98;
  border-radius: 50%;
  z-index: 100;
  opacity: 0.45 !important;
  animation: pulse 2s infinite;
}

.btn-sms:hover {
  background-color:#172E3A !important;
  color: #F5FBFB !important;
}

.btn-sms-clicked {
  background-color: #172E3A !important;
  color: #F5FBFB !important;
  border-radius: 2px !important;
}

.sms-list-info {
  font-size: 16px;
  color: grey;
  font-weight: 300;
  //margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px auto 0px auto;

  @include for-phone-only {
    font-size: 10px;
  }

}

.sms-list-empty-info {
  font-size: 16px;
  color: grey;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

.sms-list {
  overflow-y: scroll;
  position: relative;
  width: 100%;
  max-height: 50vh;
  padding-right: 16px !important;

  .sms-list-item:last-of-type {
    border-bottom: unset;
  }
}

.sms-list-item-header {
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  color: #576464;
  font-weight: 500;
  padding: 8px 0px;

  @include for-phone-only {
    font-size: 14px;
  }

  //background: green;
}

.sms-list-item .ui.grid > .row > .column {
  display: flex !important;
  justify-content: unset !important;
  align-items: unset !important;
}
