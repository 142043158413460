@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}


.upgrade-modal-content {
  div {
    margin: 8px 0px;
  }
}

.btn-i-understand {
  background-color: #0E3B49 !important;
  color: #EDF8F8 !important;
}

.sms-list-modal {

  //max-height: 90vh !important;

  .sms-modal-header {
    min-height: 25px;
    //width: 480px;
    color: #576464 !important;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 22px;
    //font-weight: 300 !important;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 16px;

    strong {
      color: #0E3B49;
      //font-weight: 300;
    }
  }

  .btn-close {
    height: 48px;
    min-width: 118px;

    border-radius: 7px !important;
    background-color: #0E3B49 !important;
    color: #EDF8F8 !important;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    text-align: center !important;

    @include for-phone-only {
      //grid-column: 1/5;
      //height: 64px;
      height: 42px;
      font-size: 12px;
      line-height: 19px;
      //margin: 16px 0px 0px 0px !important;
    }

    @include for-tablet-portrait-up {
      //grid-column: 4/7;
      //height: 64px;
      font-size: 14px;
      line-height: 16px;

      margin-left: 5px !important;
    }

    @include for-desktop-up {
      //grid-column: 8/11;
      //height: 64px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .btn-close:hover {
    color: white;
    font-size: 15px !important;
    line-height: 20px !important;
    background-color: #f99066 !important;
    transition: 0.3s !important;
  }

}

.sms-list-modal.modal > .content {
  padding: 40px !important;

  @include for-phone-only {
    padding: 12px !important;
  }
}