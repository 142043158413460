
.mailSummaryWrapper {
  box-sizing: border-box;

  padding: 24px 24px 0px; // 24px 0px;
  height: 96px;

  border: 1px solid #F2F3F3;
  border-radius: 12px;
  background: #FCFCFC;

  display: grid;
  grid-template-columns: max-content 1fr max-content;
  //align-items: center;
  gap: 16px;

  cursor: pointer;
}

.mailSummaryWrapper.compromised {
  border: solid 1px #FFD6D8;
  background: #FFF5F5;
}

.mailSummaryWrapper.unread {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); //, 0px 0px 0px 4px #F4EBFF;
}

.avatarWrapper {
  display: flex;
  position: relative;
  width: 48px;
  height: 48px;

  img {
    max-height: 40px;
    max-width: 40px;
  }

  margin-bottom: 24px;
}

.avatarStatus {
  position: absolute;
  left: 50%;
  right: 0%;
  top: 50%;
  bottom: 0%;
  border-radius: 8px;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  max-width: 358px;
  height: 60px;
}

.sender {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  /* Neutral/80 */
  color: #505A5E;

  margin-top: -4px;
}

.senderAddress {
  /* Headline 8 */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */
  text-align: right;

  /* Neutral/80 */
  color: #505A5E;
}

.compromised .sender {
  color: #F4484E;
}

.unread .sender {
  font-weight: 600;
}

.subject {

  max-width: 358px;
  height: 32px;

  /* Paragraph 3 */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #153F4C;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

    //display: flex;
    //align-items: center;
}

.compromised .subject {
  color: #F4484E;
}

.unread .subject {
  font-weight: 600;
}

.dateAndStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #153F4C;

  margin-top: -24px
}

.compromised .dateAndStatusWrapper {
  color: #F4484E;
}

.unread .dateAndStatusWrapper {
  font-weight: 600;
}

.iconInfoCompromised {
  cursor: pointer;
}

.popupWrapper {
  box-sizing: border-box !important;
  padding: 24px 24px 16px !important;
  max-width: 322px !important;
  background: #FFFFFF;

  /* Neutral/0 */
  border: 1px solid #F2F3F3 !important;
  box-shadow: 0px 8px 24px 4px rgba(21, 63, 76, 0.05) !important;
  border-radius: 12px !important;
}

.popupHeader {
  ont-family: 'Work Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #505A5E !important;
  margin-bottom: 4px !important;
}

.popupContent {
  font-family: 'Work Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #153F4C !important;
}

.popupContent .senderDomain {
  color: #f7757a;
}

.popupContent .identityDomain {
  color: #78c7c7;
}

.mailSummaryWrapper.extended {
  border-radius: 12px 12px 0px 0px;
  height: unset;
  border-bottom: 0px;
}

.extended .descriptionWrapper {
  height: unset;
}

.trustSenderBtn {
  border-radius: 7px !important;
  background-color: #0E3B49 !important;
  color: #EDF8F8 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  margin: 16px 0px 0px 0px !important;

  padding: 8px 12px;
  font-size: 12px;
  line-height: 15px;
}

.trustSenderBtn:hover {
  background-color: #f99066 !important;
  transition: 0.3s !important;

  i {
    font-size: 16px;
  }
}

