@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.menu {
    position: absolute;
    width: 300px;
    height: 100vh;
    background-color: #ffffff;
    text-transform: uppercase;

    @include for-phone-only {
        display: none;
    }

    @include for-tablet-portrait-up {
        display: none;
    }

    @include for-desktop-up {
        display: initial;
    }
}

.menu-logo {
    margin: 128px 0px 96px 20%;

    @media (max-height: 768px) {
        margin: 64px 0px 48px 20%;
    }

    @media (max-height: 650px) {
        margin: 32px 0px 24px 20%;
    }

    @media (max-height: 570px) {
        margin: 16px 0px 12px 20%;
    }

}

.menu-mobile {
    text-transform: uppercase;

    @include for-desktop-up {
        display: none;
    }

}

.mailbox-wrapper.mail-only + .menu-mobile {
    display: none;
}

.menu-mobile-content {
    background-color: white;
    //background-color: #d3dede;
    width: 100%;
    /*padding-left: 20%;*/
    //padding-top: 16px;
    font-size: 16px;
    //position: absolute;
    //top: 62px;
    z-index: 10;
    /*box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25)*/
    box-shadow: 0 25px 20px -2px rgba(0,0,0,0.25);
    border-top: solid 2px #f5fbfb;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.menu-item-active {
    margin: 8px 0px 8px 20%;
    //color: #4fbbbc;
    color: #0E3B49;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    font-weight: 700;
    cursor: pointer;
    letter-spacing: 1px;

    display: flex;
    align-items: center;
}

.menu-item {
    margin: 8px 0px 8px 20%;
    //color: #172E3A;
    color: #576464;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    font-weight: 300;
    cursor: pointer;
    letter-spacing: 1px;

    display: flex;
    align-items: center;
}

.menu-item:last-child {
    position: absolute;
    bottom: 24px;
}


.menu-item-mobile {
    //background: yellow;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between; //center;
    //color: #0e3b49cd;
    color: #576464;
    position: relative;

    //height: 65px;
    //line-height: 65px;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    //font-size: 0px;
    cursor: pointer;
    letter-spacing: 1px;
    padding: 12px 8px 4px 8px;

    i {
        //margin-bottom: 4px;
        font-size: 21px;
        line-height: 21px;
        color: #576464;
    }

    span {
        //margin-top: 4px;
        margin-top: 12px;
    }

    div {
        span {
            margin-top: 4px;
        }
    }
}

.menu-item-mobile > .ui.label {
    padding: 4px;
}

.menu-item-mobile.active {
    //color: #4FBBBC;
    color: #0E3B49;
    font-weight: 600;
    //font-size: 12px;

    i {
        //margin-bottom: 4px;
        //font-size: 16px;
        color: #0E3B49;
        font-size: 21px;
        line-height: 21px;
    }
}

.widget-addon-panel {
    position: absolute;
    bottom: 100px;

    width: 90%;
    margin: 0 5%;
    padding: 24px 16px;
    background: #beefeb;
    text-transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    border-radius: 6px;

    span {
        font-weight: 500;
    }

    a {
        color: dimgrey;
    }

    .widget-addon-panel-btn {
        border-radius: 7px !important;
        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;
        font-family: 'Work Sans', sans-serif !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        text-align: center !important;
        margin: 16px 0px 0px 0px !important;

        height: 48px;
        font-size: 12px;
        line-height: 15px;
    }

    .widget-addon-panel-desc {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        color: grey;
        margin-top: 8px;
        cursor: pointer;
    }

    .widget-addon-panel-description {
        @media (max-height: 520px) {
            display: none;
        }
    }

    @media (max-height: 650px) {
        padding: 12px 8px;
    }

    @media (max-height: 570px) {
        padding: 6px 4px;
    }
}