@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.signup-grid-wrapper {

    display: grid;
    max-height: 100%;

    //grid-template-rows: 1fr max-content; //minmax(min-content, max-content);
    grid-template-rows: content 1fr;

    @include for-tablet-landscape-up {
        grid-template-columns: 1fr max-content;
        grid-template-rows: unset;
    }

    @include for-big-desktop-up {
        grid-template-columns: 3fr 2fr;
        //grid-template-columns: 1fr max-content;
    }



}

.signup-desc-wrapper {
    ///*background-color: #E8F8F8 !important;*/
    background-image: url('../../assets/images/noid-login-background-ok.jpg');
    background-size: cover;
    background-position: 0% 65%;

    //background: #edf8f8;

    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    //height: 100%;
    padding: 24px 10% !important;
    position: relative;

    @include for-phone-only {
        //padding: 8px 5% 8px 5% !important;
        padding: 8px 6% 8px 9% !important
    }
}

.signup-header {
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 1.3;
    margin-bottom: 0px;
}

.signup-subheader {
    font-weight: 300;
    letter-spacing: 5px;
    word-spacing: 14px;
    line-height: 1.3;
    color: white;
    //color: #0E3B49;

    font-size: 26px;

    @include for-phone-only {
        font-size: 20px;
        word-spacing: unset;
    }

    @include for-tablet-portrait-up {
        font-size: 36px;
    }

    @include for-tablet-landscape-up {
        font-size: 24px;
    }

    @include for-desktop-up {
        //font-size: 50px;
        font-size: 38px;
    }

    @include for-big-desktop-up {
        font-size: 50px;
    }
}

.signup-subheader2 {
    font-weight: 700;
    //font-size: 51px;
    letter-spacing: 4px;
    line-height: 1.3;
    word-spacing: 12px;
    margin-top: 12px;
    color: white;

    font-size: 27px;

    @include for-phone-only {
        font-size: 20px;
        word-spacing: unset;
    }

    @include for-tablet-portrait-up {
        font-size: 37px;
    }

    @include for-tablet-landscape-up {
        font-size: 24px;
    }

    @include for-desktop-up {
        font-size: 39px;
    }

    @include for-big-desktop-up {
        font-size: 51px;
    }

    //color: #576464;
}

.signup-head {
    /*background-color: #E8F8F8;*/
    /*padding: 20% 10% 0 10%;*/
    /*background-color: yellow;*/
    /*padding: 20% 10% 0 10%;*/
    //left: 10%;
    //bottom: 30vh;
    //position: absolute;
    //margin-top: 45vh;
    z-index: 1;
    //text-align: right;

    margin-bottom: 5vh;
    display: none;

    @include for-phone-only {
        margin-bottom: 5vh;
    }

    @include for-tablet-landscape-up {
        margin-bottom: 25vh;
        display: unset;
    }

    @include for-desktop-up {
        margin-bottom: 20vh;
    }

}

.signup-logo {
    //position: absolute;
    //top: 9%;
    //left: 9%;
    //height: 96px !important;
    width: 128px !important;

    /*margin: 0 auto;*/
    /*display: none !important;*/

    @include for-phone-only {
        width: 64px !important;
        margin-left: -8px;
    }

    @include for-tablet-portrait-up {
        width: 96px !important;
        margin-left: 0px;
    }

    @include for-desktop-up {
        width: 128px !important;
        margin-left: -16px;
    }
}

.signup-form-wrapper {
    display: flex;
    //justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 84px 128px;

    //background: yellow;

    @include for-phone-only {
        padding: 24px 24px 24px;
    }

    @include for-tablet-landscape-up {
        padding: 72px 96px;
    }

    @include for-desktop-up {
        padding: 84px 128px;
    }
}

.signup-form-header {
    font-weight: 200;
    letter-spacing: 5px;
    word-spacing: 14px;
    line-height: 1.3;
    //color: white;
    color: #0E3B49;
    font-size: 26px;

    margin-bottom: 48px;

    @include for-phone-only {
        font-size: 20px;
        word-spacing: unset;
        margin: 24px 0px 24px;
    }

    @include for-tablet-portrait-up {
        font-size: 36px;
    }

    @include for-tablet-landscape-up {
        font-size: 30px;
    }

    @include for-desktop-up {
        //font-size: 50px;
        font-size: 38px;
    }

    @include for-big-desktop-up {
        font-size: 50px;
    }
}

.signup-form {
    text-align: center;
    max-width: 400px;
    //background: pink;

    ::placeholder {
        //opacity: 1;
        opacity: 0.45;
        //z-index: 2;
        color: #576464 !important;
    }

    .signup-input {

        width: 100%;
        //border: 1px solid #0E3B4944 !important;
        border-radius: 7px;
        height: 64px;
        //width: 90%;
        margin-bottom: 16px;


        input {
            width: 100% !important;
            box-sizing: border-box;
            border: 1px solid rgba(38, 94, 95, 0.24);
            border-radius: 7px;
            background-color: #FFFFFF;
            font-size: 23px;
            //box-shadow: 0 12px 24px -2px rgba(14, 59, 73, 0.1) !important;

            color: #576464;
            font-family: 'Work Sans', sans-serif !important;
            letter-spacing: 0;

            //height: 16px;
            //width: 88px;
            //opacity: 0.45;
            font-size: 18px;
            line-height: 16px;
        }

        input:focus {
            //border: 0px;
            border: 1px solid rgba(4, 4, 4, 0.24)!important;
            //color: #F08769 !important;

            //@include for-tablet-portrait-up {
            //    box-shadow: 0 16px 24px 4px rgba(14, 59, 73, 0.1),
            //    0 24px 24px 0px rgba(7, 0, 0, 0.1) !important;
            //}
        }

        @include for-phone-only {
            height: 64px;
            font-size: 16px;
            line-height: 19px;
            margin: 0px 0px 12px 0px;
        }

        @include for-tablet-portrait-up {
            height: 64px;
            font-size: 16px;
            line-height: 19px;
        }

        @include for-desktop-up {
            height: 64px;
            font-size: 16px;
            line-height: 19px;
        }

        i {
            color: rgba(38,94,95,0.84);
            z-index: 3;
            margin-left: 4px !important;
            margin-right: 4px !important;
        }

    }

    .signup-input-tip {
        width: 100%;
        //margin-top: -12px;
        // margin-bottom: 16px;
        color: #576464;
        font-size: 12px;
        //text-align: left;
        font-weight: 300;
    }

    .btn-signup {
        height: 64px;
        //width: 90%;
        width: 100%;
        //max-width: 240px;

        border-radius: 7px !important;
        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;
        font-family: 'Work Sans', sans-serif !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        text-align: center !important;

        margin-top: 24px;

        @include for-phone-only {
            //grid-column: 1/5;

            //height: 64px;
            //font-size: 16px;
            //line-height: 19px;

            height: 56px;
            font-size: 14px;
            line-height: 17px;


            //margin: 16px 0px 0px 0px !important;
            margin: 24px 0px 0px !important;
        }

        @include for-tablet-portrait-up {
            //grid-column: 4/7;
            height: 64px;
            font-size: 14px;
            line-height: 16px;
            margin-left: 0px !important;
        }

        @include for-desktop-up {
            //margin-left: 5px !important;
            //grid-column: 8/11;
            height: 64px;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .btn-signup:hover {
        color: white;
        font-size: 18px !important;
        line-height: 24px !important;
        background-color: #f99066 !important;
        transition: 0.3s !important;

        @include for-phone-only {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .signup-input.ui[class*="left icon"].input > input {
        padding-left: 48px !important;
    }

}

.signup-password-warning {
    width: 100%;
    margin-bottom: 16px;
    //color: #d05c5c;
    color: #f99066;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
}

.signup-error {
    color: red;
    margin: 12px 0px;
    font-size: 14px;
}

.captcha-input {
    padding-top: 10px;
}

.captcha-input-invalid {
    border-radius: 10px;
    background-color: #FFF6F6;
}