@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}


.App {
  //position: fixed;
  display: grid;
  //grid-template-rows: 1fr min-content;
  //overflow-y: hidden;
  grid-template-rows: minmax(1px,1fr) min-content;


  //flex-direction: column;
  //height: 100vh;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  //max-height: 100%;
  //max-height: 100vh;
  //min-height: -webkit-fill-available;
  //height: -webkit-fill-available;


  @include for-desktop-up {
    flex-direction: unset;
  }


}

.noidButton {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;

  /* White / White (100%) */
  background: #FFFFFF;

  /* Neutral/5 */
  border: 1px solid #EAEBEB;
  border-radius: 12px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  /* Button Large text */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  /* Neutral/100 */
  color: #153F4C;

  cursor: pointer;
}

.noidButton > img {
  width: 24px;
  height: 24px;
}