@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.stats-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    //background: green;

    @include for-desktop-up {
        flex-wrap: unset;
        justify-content: center;
    }
}

.statistic {
    margin: 16px;
    display: flex;
    flex-flow: column;
    justify-content: center;

    @include for-desktop-up {
        padding: 0px 16px;
    }

}

.statistic:nth-of-type(1) {

    @include for-desktop-up {
        padding: 0px;
    }
}

.statistic-label {
    color: #0E3B49;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    @include for-phone-only {
        font-size: 12px;
    }

    @include for-desktop-up {
        font-size: 16px;
    }

}

.statistic-label.orange {
    color: #F08769;
}

.statistic-value {
    color: #0E3B49;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: 16px;

    @include for-phone-only {
        //margin-top: 0px;
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 26px;
    }
}

.statistic-value.orange {
    color: #F08769;
}