@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.buy-phone-number-wrapper {

    width: 100%;
    max-width: 750px; //900px;
    //background: white;

    @include for-phone-only {
        //padding: 24px 16px 0px 16px;
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    }

    @include for-tablet-portrait-up {
        padding: 24px 0px 24px 48px;
        max-width: 550px;
    }

    @include for-tablet-landscape-up {
        max-width: 750px;
    }

    @include for-desktop-up {
        padding: 40px 24px;
        max-width: 750px; //900px;
    }

    .buy-phone-number-buy-btn {
        height: 64px;


        border-radius: 7px !important;
        background-color: #0E3B49 !important;
        color: #EDF8F8 !important;
        font-family: 'Work Sans', sans-serif !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        text-align: center !important;

        @include for-phone-only {
            //grid-column: 1/5;

            //height: 64px;
            //font-size: 16px;
            //line-height: 19px;

            height: 56px;
            font-size: 14px;
            line-height: 17px;


            //margin: 16px 0px 0px 0px !important;
            margin: 0px 0px 0px 0px !important;
        }

        @include for-tablet-portrait-up {
            //grid-column: 4/7;
            height: 64px;
            font-size: 14px;
            line-height: 16px;
            margin-left: 0px !important;
        }

        @include for-desktop-up {
            margin-left: 5px !important;
            //grid-column: 8/11;
            height: 64px;
            font-size: 14px;
            //line-height: 16px;
            width: 212px;
            max-width: 240px;
        }
    }

    //.buy-phone-number-buy-btn {
    //    flex: 1 1 190px;
    //
    //    height: 64px;
    //    z-index: unset;
    //    //max-width: 240px;
    //
    //    border-radius: 7px !important;
    //    background-color: #0E3B49 !important;
    //    color: #EDF8F8 !important;
    //    font-family: 'Work Sans', sans-serif !important;
    //    font-weight: 600 !important;
    //    letter-spacing: 0 !important;
    //    text-align: center !important;
    //
    //    @include for-phone-only {
    //        //grid-column: 1/5;
    //        height: 64px;
    //        font-size: 16px;
    //        line-height: 19px;
    //        //margin: 16px 0px 0px 0px !important;
    //        margin: 0px 0px 0px 0px !important;
    //        flex: unset;
    //        width: 100%;
    //    }
    //
    //    @include for-tablet-portrait-up {
    //        //grid-column: 4/7;
    //        height: 64px;
    //        font-size: 14px;
    //        line-height: 16px;
    //
    //        //margin-left: 5px !important;
    //    }
    //
    //    @include for-desktop-up {
    //        //grid-column: 8/11;
    //        height: 64px;
    //        font-size: 14px;
    //        line-height: 16px;
    //    }
    //}

    .buy-phone-number-buy-btn:hover {
        color: white;
        font-size: 15px !important;
        //line-height: 20px !important;
        background-color: #f99066 !important;
        transition: 0.3s !important;
    }

    .buy-phone-number-buy-btn.disabled {
        background: #8ca4aa !important;
        //background: #c2d1d5 !important;
        opacity: 1 !important;
    }
}

.buy-phone-number ::placeholder {
    color: grey !important;
}

.buy-phone-number {

}

.buy-phone-number > .row-no-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.buy-phone-number-input {
    font-size: 16px !important;
    height: 100%;
    width: 100%;
}

.buy-phone-number .ui.fluid.dropdown {
    font-size: 16px !important;
    border: 1px solid rgba(38, 94, 95, 0.24);
    border-radius: 7px;
    background-color: #FFFFFF;
    color: #576464;
    font-family: 'Work Sans', sans-serif !important;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    @include for-phone-only {
        height: 64px;
        font-size: 16px;
        line-height: 19px;
        margin: 0px 0px 12px 0px;
    }

    @include for-tablet-portrait-up {
        height: 64px;
        font-size: 16px;
        line-height: 19px;
    }

    @include for-desktop-up {
        height: 64px;
        font-size: 16px;
        line-height: 19px;
    }

    .dropdown.icon {
        top: unset !important;
    }

    .menu {
        height: min-content;
    }
}

.buy-phone-number-header {
    min-height: 25px;
    //width: 480px;
    color: #0E3B49;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}

.buy-phone-number-subheader {
    color: #576464;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 8px 0px;

    @include for-phone-only {
        margin: 12px 0px;
        font-size: 14px;
    }

    @include for-desktop-up {
        line-height: 28px;
        width: 609px;
    }
}

.buy-phone-number-form {

    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;

    color: #0E3B49;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    margin-top: 16px;

    @include for-phone-only {
        gap: 4px;
    }

    .buy-phone-number-country {
        z-index: 3 !important;
        flex: 1 1;
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .buy-phone-number-cost {
        flex: 1 1 190px;
        //background: blue;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0px 16px;

        @include for-phone-only {
            margin: 4px 0px 16px;
        }

        .phone-cost-value {
            display: flex;
            justify-content: flex-end;
            //justify-content: center;
            align-items: center;
            font-weight: 500;
            margin-left: 8px;

            .phone-cost-value-currency {
                font-size: 14px;
                line-height: 20px;
                margin-left: 5px;
            }
        }
    }

    .ui.fluid.dropdown {
        min-width: 196px !important;
    }

}


.buy-phone-message-wrapper {
    /*min-height: 80px;*/
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    margin-top: 16px;
    height: 64px;
    //margin-bottom: 16px;
}

.buy-phone-error {
    height: 100%;
    background-color: #f76262;
    font-size: 19px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    font-weight: 300;
    align-items: center;
    color: white;
    padding: 16px 32px;
    border-radius: 4px;
    text-align: center;

    @include for-phone-only {
        width: 100%;
    }

    i {
        margin-right: 16px;
    }
}

.buy-phone-success {
    height: 100%;
    background-color: #3E8A98; //#21ba45;
    font-size: 19px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    font-weight: 300;
    align-items: center;
    color: white;
    padding: 16px 32px;
    border-radius: 4px;
    text-align: center;

    @include for-phone-only {
        width: 100%;
    }

    i {
        margin-right: 16px;
    }
}

.mobile-break { display: none; }

.new-number-content {
    display: flex !important;
    justify-content: flex-end !important;
}

.buy-phone-other-country-info {
    display: flex;
    flex-direction: row;
    gap: 4px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 15px;
    margin-top: 12px;
    color: #0E3B49;
    font-weight: 300;
    cursor: pointer;
}