@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

.phone-list-wrapper {
    width: 100%;
    position: relative;
    z-index: 3;
    //background: green;
    padding: 0px 12px;


    @include for-phone-only {
        width: unset;
        margin: auto -16px;
        padding: unset;
    }
}

.phone-list-wrapper::-webkit-scrollbar {
    display: none;
}

.phone-list {

    display: grid;

    @include for-phone-only {
        display: flex;
        overflow-x: auto;
        padding: 0px;
        //grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    padding: 24px 0px;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.phone-list::-webkit-scrollbar {
    display: none;
}

.phone-list:after {

    @include for-phone-only {
        content: '';
        min-width: 22px;
    }
}

//.phone-list:before {
//
//    @include for-phone-only {
//        content: '';
//        min-width: 22px;
//    }
//}

.phone-item {
    margin: 16px;
    background: white;
    padding: 24px 16px;
    border-radius: 6px;
    box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);
    //background: pink;


    @include for-phone-only {
        //margin: 16px;
        flex: 0 0 300px;
        margin-bottom: 42px;
        box-shadow: 0 25px 30px -12px rgba(0,0,0,0.25);
        padding: 32px 24px;
    }

    .phone-details {

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        //background: blue;
        padding: 12px 0px;
        gap: 24px;

        @include for-phone-only {
            //flex-direction: column;
            //gap: 24px;
        }

        img {
            @include for-phone-only {
                height: 20px
            }
        }

        .phone-number {
            font-size: 26px;
            font-weight: 400;
            //margin-left: 24px;

            @include for-phone-only {
                margin-left: 8px;
                font-size: 21px;
            }
        }
    }

    .phone-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        //background: green;
        padding: 12px 0px;
        gap: 16px;
        margin-top: 16px;

        @include for-phone-only {
            //background: pink;
            margin-top: 0px;
        }

        .phone-action-btn {
            flex: 1 1 66px;
            max-width: 196px;
            height: 48px;
            z-index: unset;
            //max-width: 240px;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 7px !important;


            //background-color: #0E3B49 !important;
            //color: #EDF8F8 !important;

            background-color: white !important;
            color:  #0E3B49 !important;
            border: 1px solid #0E3B49;

            font-family: 'Work Sans', sans-serif !important;
            font-weight: 600 !important;
            letter-spacing: 0 !important;
            text-align: center !important;

            @include for-phone-only {
                max-width: 124px !important;
                font-size: 14px;
                line-height: 19px;
                //margin: 16px 0px 0px 0px !important;
                margin: 0px 0px 0px 0px !important;
                //flex: unset;
                //width: 100%;
                //max-width: 128px;
            }

            @include for-tablet-portrait-up {
                font-size: 14px;
                line-height: 19px;

                //margin-left: 5px !important;
            }

            @include for-desktop-up {
                font-size: 14px;
                line-height: 19px;
            }

            i {
                font-size: 16px !important;
                line-height: 16px !important;
            }
        }

        .phone-action-btn:hover {
            //color: white !important;
            font-size: 15px !important;
            line-height: 20px !important;
            //background-color: #f99066 !important;
            transition: 0.3s !important;

            background-color: #0E3B49 !important;
            color:  white !important;
        }
    }

}

.phone-name-column {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    font-size: 27px;
    font-weight: 300;
    color: #172E3A;
}

.button-column {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.phone-list-item .ui.grid > .row > .column {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
 }

.phone-list-item .ui.grid > .row > .phone-status.column {
    display: flex !important;
    justify-content: right !important;
    align-items: center !important;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        opacity: 0.45 !important;
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        opacity: 0.45 !important;
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        opacity: 0.45 !important;
    }
}