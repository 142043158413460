.password-changer-wrapper {
  background: pink;
  width: 100%;
  max-width: 312px;
  padding: 16px 16px 0px;
  //border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 8px;
  //background: #f9f5f5;
}

.password-changer-wrapper label {
  color: grey;
  font-weight: 300;
}

.password-changer-pwd-wrapper {
  display: flex !important;
  flex-direction: row !important;
  background: blue !important;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  i {
    cursor: pointer;
    color: #0E3B49;
  }
}

.password-changer-pwd-inner-wrapper {
  display: flex !important;
  flex-direction: row !important;
  gap: 8px;
  align-items: center;

  i {
    cursor: pointer;
    color: #0E3B49;
  }
}

.password-changer-length-panel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: #0E3B49;

  span {
    padding-right: 5px;
  }
}

.password-changer-characters-panel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}


.characters {
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
  color: rgba(159, 159, 159, 0.87);
  border: 1px solid #CCE2FF;
  background: white;
}

.characters.enabled {
  background: rgb(171, 226, 251);
  color: white;
}

.password-changer-password {
  width: 128px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding-top: 4px;
}

.btn-save {
  height: 32px;
  width: 100%;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  border-radius: 4px !important;
  border: 0px;
  background-color: #0E3B49 !important;
  color: #EDF8F8 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;


  line-height: 17px;
  margin: 24px 0px 0px 0px !important;
}

.btn-save:hover {
  font-size: 12.5px;
  transition: 0.3s;
  background-color: #f99066 !important;
  color: white;
}

.btn-cancel {
  height: 32px;
  width: 50%;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  border-radius: 4px !important;
  border: 1px solid  #0E3B49 !important;
  background-color: transparent;
  color: #0E3B49 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;


  line-height: 17px;
  margin: 24px 0px 0px 0px !important;
}

.password-changer-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: yellow;
  height: 4px;
  border-radius: 6px;
}